<template>
  <div>
    <nav-bar></nav-bar>
    <v-row justify="center">
      <v-col cols="12" sm="10">
        <h1>{{ $t("pageLegals.title") }}</h1>
        <p>
          {{ $t("pageLegals.intro") }}
        </p>

        <h2>{{ $t("pageLegals.siteEditionTitle") }}</h2>
        <p>
          {{ $t("pageLegals.siteEditionContent") }}
        </p>
        <p>
          {{ $t("pageLegals.vatNumber") }}
        </p>

        <h2>{{ $t("pageLegals.hostingTitle") }}</h2>
        <p>
          {{ $t("pageLegals.hostingContent") }}
        </p>

        <h2>{{ $t("pageLegals.publicationDirectorTitle") }}</h2>
        <p>
          {{ $t("pageLegals.publicationDirectorContent") }}
        </p>

        <h2>{{ $t("pageLegals.contactTitle") }}</h2>
        <p>
          {{ $t("pageLegals.contactContent") }}
        </p>
        <p>
          {{ $t("pageLegals.vatNumber") }}
        </p>

        <h2>{{ $t("pageLegals.privacyTitle") }}</h2>
        <p>
          {{ $t("pageLegals.privacyContent") }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
export default {
  name: "MentionsLegales",
  components: {
    NavBar,
  },
};
</script>

<style scoped>
.mentions-legales {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.mentions-legales h1,
.mentions-legales h2 {
  color: #333;
}

.mentions-legales p {
  margin-bottom: 10px;
  line-height: 1.6;
}
</style>
